import { useState, useEffect } from 'react'
import { SimpleCard } from '../../../matx'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import React from 'react'
import { payoutTransactionDetails } from '../../services/APIClient'
import 'bootstrap/dist/css/bootstrap.min.css'
import { convertToBrazilianDateTimeZone, currencyWithTwoDecimals } from '../../services/FormatterService'
import PayeeMerchantInPayoutDetails from './PayeeMerchantInPayoutDetails'

export function PayoutTransactionDetails({ id }) {
  const [details, setDetails] = useState(null)

  useEffect(() => {
    payoutTransactionDetails(id).then((resp) => {
      setDetails(resp)
    })
  }, [id])

  return (
    <div className="First card">
      <SimpleCard title="Transaction Details">
        <div>
          {details === null ? (
            ''
          ) : (
            <Container>
              <h5>Transaction:</h5>
              <BTable striped bordered hover>
                <tbody>
                  <tr>
                    <td>Id</td>
                    <td>{details.id}</td>
                  </tr>
                  <tr>
                    <td>Externalized Id</td>
                    <td>{details.externalizedId}</td>
                  </tr>
                  <tr>
                    <td>Intermediary payout id</td>
                    <td>{details.intermediaryPayoutId}</td>
                  </tr>
                  <tr>
                    <td>Merchant payout id</td>
                    <td>{details.merchantPayoutId}</td>
                  </tr>
                  <tr>
                    <td>Provider transaction id</td>
                    <td>{details.providerTransactionId}</td>
                  </tr>
                  <tr>
                    <td>Provider name</td>
                    <td>{details.provider}</td>
                  </tr>
                  <tr>
                    <td>Customer name</td>
                    <td>{details.payeeName}</td>
                  </tr>
                  <tr>
                    <td>Payment method</td>
                    <td>{details.paymentMethod}</td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{currencyWithTwoDecimals(details.amount)}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{details.status}</td>
                  </tr>
                  <tr>
                    <td>Status details</td>
                    <td>{details.statusDetails}</td>
                  </tr>
                  <tr>
                    <td>Purpose</td>
                    <td>{details.purpose}</td>
                  </tr>
                  <tr>
                    <td>Created At</td>
                    <td>{convertToBrazilianDateTimeZone(details.createdAt)}</td>
                  </tr>
                  <tr>
                    <td>Updated At</td>
                    <td>{convertToBrazilianDateTimeZone(details.updatedAt)}</td>
                  </tr>
                </tbody>
              </BTable>

              {details.fees ? <FeesTable fees={details.fees}></FeesTable> : <></>}

              <PayeeMerchantInPayoutDetails details={details.payeeMerchant} />
            </Container>
          )}
        </div>
      </SimpleCard>
    </div>
  )
}

function FeesTable({ fees }) {
  return (
    <>
      <h4>Fees:</h4>
      <BTable striped bordered hover>
        <tbody>
          <tr>
            <td>MDR amount</td>
            <td>{fees.mdrAmount}</td>
          </tr>
          <tr>
            <td>Inbound base FX rate</td>
            <td>{fees.fx.inboundBaseFxRate}</td>
          </tr>
          <tr>
            <td>Inbound LL FX rate</td>
            <td>{fees.fx.inboundLLFxRate}</td>
          </tr>
          <tr>
            <td>Inbound FX tax amount for payout amount and payment fees</td>
            <td>{fees.fx.inboundFxTaxAmountForPayoutAmountAndPaymentFees}</td>
          </tr>
          <tr>
            <td>Outbound base FX rate</td>
            <td>{fees.fx.outboundBaseFxRate}</td>
          </tr>
          <tr>
            <td>Outbound LL FX rate</td>
            <td>{fees.fx.outboundLLFxRate}</td>
          </tr>
          <tr>
            <td>Outbound FX tax amount for payout amount and payment fees</td>
            <td>{fees.fx.outboundFxTaxAmountForPayoutAmountAndPaymentFees}</td>
          </tr>
          <tr>
            <td>FX spread amount</td>
            <td>{fees.fx.fxSpreadAmount}</td>
          </tr>
          <tr>
            <td>Foreign currency</td>
            <td>{fees.fx.foreignCurrency}</td>
          </tr>
        </tbody>
      </BTable>
    </>
  )
}

export default PayoutTransactionDetails
