import moment from 'moment-timezone'

export function convertToBrazilianDate(dateString) {
  if (dateString != null) {
    return moment.tz(dateString, 'America/Sao_Paulo').format('YYYY-MM-DD')
  }
  return dateString
}

export function convertToBrazilianDateTimeZone(dateString) {
  if (dateString != null) {
    return moment.tz(dateString, 'America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss')
  }
  return dateString
}

export function convertToBrazilianTimeZone(dateString) {
  if (dateString != null) {
    return moment.tz(dateString, 'America/Sao_Paulo').format('HH:mm:ss')
  }
  return dateString
}

export function currencyWithTwoDecimals(amount, currencySymbol = 'R$') {
  if (amount == null) {
    return ''
  }
  return `${currencySymbol} ${amount.toFixed(2)}`
}

export function currency(amount, currencySymbol = 'R$') {
  if (amount == null) {
    return ''
  }
  return `${currencySymbol} ${amount}`
}
