import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import Select from 'react-select'

export default function BankTransferProviderChargeInsert({ item, onChange, chargeValueTypes }) {
  const [chargeBeingInserted, setChargeBeingInserted] = useState({
    chargeValue: null,
    chargeValueType: null,
    minimumFixedChargeValue: 0,
    accountingEntryType: null,
    bankTransferType: null,
    serviceType: 'BANK_TRANSFER',
    ...item,
  })

  const onChangeProperty = (propertyName, value) => {
    chargeBeingInserted[propertyName] = value
    setChargeBeingInserted({ ...chargeBeingInserted })
    onChange(chargeBeingInserted)
  }

  const allAccountingEntryTypes = ['CREDIT', 'DEBIT'].map((item) => ({ value: item, label: item }))
  const allBankTransferTypes = ['PIX', 'BANK_TRANSFER'].map((item) => ({ value: item, label: item }))

  return (
    <>
      <Container>
        <BTable className="center">
          <tbody>
            <tr>
              <td className="column-table">Charge value:</td>
              <td>
                <input
                  className="accounting-register-input-text"
                  name="chargeValue"
                  onChange={(event) => onChangeProperty('chargeValue', event.target.value)}
                  id="chargeValue"
                  type="number"
                  placeholder="charge value"
                  defaultValue={chargeBeingInserted.chargeValue}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Charge value type:</td>
              <td>
                <Select
                  name="chargeValueType"
                  id="chargeValueType"
                  onChange={(event) => onChangeProperty('chargeValueType', event.value)}
                  options={chargeValueTypes}
                  placeholder="charge value type"
                  value={chargeValueTypes.filter((option) => option.value === chargeBeingInserted.chargeValueType)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Accounting entry type:</td>
              <td>
                <Select
                  name="accountingEntryType"
                  id="accountingEntryType"
                  onChange={(event) => onChangeProperty('accountingEntryType', event.value)}
                  options={allAccountingEntryTypes}
                  placeholder="accounting entry type"
                  value={allAccountingEntryTypes.filter(
                    (option) => option.value === chargeBeingInserted.accountingEntryType,
                  )}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Bank transfer type:</td>
              <td>
                <Select
                  name="bankTransferType"
                  id="bankTransferType"
                  onChange={(event) => onChangeProperty('bankTransferType', event.value)}
                  options={allBankTransferTypes}
                  placeholder="bank transfer type"
                  value={allBankTransferTypes.filter((option) => option.value === chargeBeingInserted.bankTransferType)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Minimum fixed price</td>
              <td>
                <input
                  className="account-register-input-text"
                  name="minimumFixedChargeValue"
                  onChange={(event) => onChangeProperty('minimumFixedChargeValue', event.target.value)}
                  id="minimumFixedChargeValue"
                  type="number"
                  placeholder="Minimum fixed price"
                  defaultValue={chargeBeingInserted.minimumFixedChargeValue}
                  required
                />
              </td>
            </tr>
          </tbody>
        </BTable>
      </Container>
    </>
  )
}
