import React, { Component } from 'react'
import { Fragment } from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material'
import Select from 'react-select'
import { allAccountsInfo, createIntermediary, findAllIntermediariesByAccountId } from '../../../../services/APIClient'
import { convertToBrazilianDateTimeZone } from '../../../../services/FormatterService'

class IntermediaryTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      defaultAccountInfo: '',
      accountsList: [],
      intermediariesList: [],
      payTo: [
        { value: 'PROVIDER', label: 'INTERMEDIARY' },
        { value: 'ACCOUNT', label: 'ACCOUNT' },
      ],
      intermediaryRoles: [
        { value: 'BOLETO_READ', label: 'BOLETO READ' },
        { value: 'BOLETO_WRITE', label: 'BOLETO WRITE' },
        { value: 'CREDIT_CARD_READ', label: 'CREDIT CARD READ' },
        { value: 'CREDIT_CARD_WRITE', label: 'CREDIT CARD WRITE' },
        { value: 'PIX_READ', label: 'PIX READ' },
        { value: 'PIX_WRITE', label: 'PIX WRITE' },
        { value: 'PAYOUT_PIX_READ', label: 'PAYOUT PIX READ' },
        { value: 'PAYOUT_PIX_WRITE', label: 'PAYOUT PIX WRITE' },
        { value: 'KYC_CHECKS', label: 'KYC CHECKS' },
        { value: 'IDENTITY_CHECKS', label: 'IDENTITY CHECKS' },
        { value: 'AML_CHECKS', label: 'AML CHECKS' },
        {
          value: 'PIX_TRANSACTION_IN_FOREIGN_CURRENCY_WITH_FX_LOCK',
          label: 'PIX TRANSACTION IN FOREIGN CURRENCY WITH FX LOCK',
        },
        {
          value: 'CREDIT_CARD_TRANSACTION_IN_FOREIGN_CURRENCY_WITH_FX_LOCK',
          label: 'CREDIT CARD TRANSACTION IN FOREIGN CURRENCY WITH FX LOCK',
        },
        {
          value: 'BOLETO_TRANSACTION_IN_FOREIGN_CURRENCY_WITH_FX_LOCK',
          label: 'BOLETO TRANSACTION IN FOREIGN CURRENCY WITH FX LOCK',
        },
      ],
      selectedIntermediary: '',
      selectedIntermediaryRoles: [],
      selectedPayTo: null,
      accountId: this.props.accountId,
    }
  }

  onChangeIntermediaryRoles = (events) => {
    if (events) {
      this.setState({
        selectedIntermediaryRoles: events.map((event) => event.value),
      })
    }
  }

  onChangePayTo = (event) => {
    this.setState({ selectedPayTo: event.value })
  }

  onChangeSelectedAccount = (event) => {
    this.setState({ selectedAccount: event.value })
  }

  onChangeSelectedIntermediary = (event) => {
    this.setState({
      selectedIntermediary: event.value,
    })
  }

  componentDidMount() {
    findAllIntermediariesByAccountId(this.state.accountId).then((intermediariesByAccountId) => {
      this.setState({ intermediariesList: intermediariesByAccountId.result })
      allAccountsInfo().then((allAccounts) => {
        this.findSelectedAccountInfo(allAccounts)
        this.filterByCreatedIntermediary(allAccounts, intermediariesByAccountId)
      })
    })
  }

  filterByCreatedIntermediary(allAccounts, intermediariesByAccountId) {
    const newArray = allAccounts.result.filter((account) => {
      return !intermediariesByAccountId.result.some((intermediary) => {
        return account.id === intermediary.provider.id
      })
    })
    this.setState({
      accountsList: newArray.map((account) => {
        return {
          value: account.id,
          label: account.softDescriptor,
        }
      }),
    })
  }

  findSelectedAccountInfo(accounts) {
    let defaultAccountInfo = accounts.result.find((account) => account.id === parseInt(this.state.accountId))

    this.setState({
      defaultAccountInfo: defaultAccountInfo,
    })
  }

  updateIntermediaryList() {
    findAllIntermediariesByAccountId(this.state.accountId).then((intermediariesByAccountId) => {
      this.setState({ intermediariesList: intermediariesByAccountId.result })
    })
  }

  handleCreateIntermediary() {
    const body = {
      payTo: this.state.selectedPayTo,
      roles: this.state.selectedIntermediaryRoles,
    }
    this.setState({
      isLoading: true,
    })

    createIntermediary(this.state.accountId, this.state.selectedIntermediary, body)
      .catch(() => alert('Something went wrong. Please, contact system administrator.'))
      .finally(
        this.setState({
          isLoading: false,
        }),
        this.updateIntermediaryList(),
      )
  }

  swapPayTo(payTo) {
    switch (payTo) {
      case 'PROVIDER':
        return 'INTERMEDIARY'
      default:
        return payTo
    }
  }

  render() {
    return (
      <Fragment>
        <div className="card-modal-report">
          <div style={{ paddingLeft: '30px' }}>
            <div>
              <label>Create intermediary by selected account</label>
            </div>
            <div className="row" style={{ paddingTop: '20px' }}>
              <div className="col-md-6 col-4">
                <input
                  type="text"
                  value={this.state.defaultAccountInfo.softDescriptor}
                  readOnly
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
              <div className="col-md-6 col-4">
                <Select
                  defaultValue={[this.state.accountsList[0]]}
                  name="accountType"
                  id="accountType"
                  onChange={this.onChangeSelectedIntermediary}
                  options={this.state.accountsList}
                  placeholder="Intermediary"
                  classNamePrefix="Intermediary"
                  required
                />
              </div>
            </div>
            <div className="row" style={{ paddingTop: '20px' }}>
              <div className="col-md-6 col-4">
                <Select
                  defaultValue={[]}
                  isMulti
                  name="status"
                  id="status"
                  onChange={this.onChangeIntermediaryRoles}
                  options={this.state.intermediaryRoles}
                  className="basic-multi-select"
                  classNamePrefix="Permissions"
                  placeholder="Permissions"
                  required
                />
              </div>
              <div className="col-md-6 col-4">
                <Select
                  defaultValue={[]}
                  name="status"
                  id="status"
                  onChange={this.onChangePayTo}
                  options={this.state.payTo}
                  className="basic-multi-select"
                  classNamePrefix="Pay to"
                  placeholder="Pay to"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-4" style={{ paddingTop: '20px' }}>
                {this.state.isLoading ? (
                  <button className="buttonSendDate">
                    <CircularProgress size={30} />
                  </button>
                ) : (
                  <button className="buttonSendDate" onClick={() => this.handleCreateIntermediary()}>
                    Create Intermediary
                  </button>
                )}
              </div>
            </div>
            <div className="row2" style={{ marginTop: '50px' }}>
              <Table style={{ whiteSpace: 'pre' }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="px-0">Account</TableCell>
                    <TableCell className="px-0">Intermediary</TableCell>
                    <TableCell className="px-0">PayTo</TableCell>
                    <TableCell className="px-0">CreatedAt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.intermediariesList.map((intermediary, index) => (
                    <TableRow key={index} onClick={(event) => this.handleClick(event, intermediary.refundId)}>
                      <TableCell className="px-0 capitalize" align="left">
                        {intermediary.account.softDescriptor}
                      </TableCell>
                      <TableCell className="px-0 capitalize" align="left">
                        {intermediary.provider.softDescriptor}
                      </TableCell>
                      <TableCell className="px-0 capitalize" align="left">
                        {this.swapPayTo(intermediary.payTo)}
                      </TableCell>
                      <TableCell className="px-0 capitalize" align="left">
                        {convertToBrazilianDateTimeZone(intermediary.createdAt)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default IntermediaryTable
