import {
  Box,
  Collapse,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { SimpleCard } from '../../../../matx'
import { convertToBrazilianDateTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import { useEffect, useState } from 'react'

import Loading from '../../../components/Loading'
import { getTransferNetCreditedRequested } from '../../../services/APIClient'

const NetTransferRequestedDetails = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [bankTransfers, setBankTransfers] = useState()

  useEffect(() => {
    const getNetCreditRequested = async () => {
      const resp = await getTransferNetCreditedRequested()

      setBankTransfers(resp.content)
      setIsLoading(false)
    }

    getNetCreditRequested()
  }, [])

  return (
    <SimpleCard title="Last 5 net credit transfers made">
      {isLoading && bankTransfers === undefined ? (
        <Loading />
      ) : bankTransfers.length > 0 ? (
        <NetTransferTable bankTransfers={bankTransfers} />
      ) : (
        <TableRow>
          <TableCell>There is no withdrawn settlement</TableCell>
        </TableRow>
      )}
    </SimpleCard>
  )
}

const NetTransferTable = ({ bankTransfers }) => (
  <Table>
    <TableHead>
      <TableCell align="left" />
      <TableCell align="left">Bank transfer ID</TableCell>
      <TableCell align="left">Bank Account Type</TableCell>
      <TableCell align="left">Amount</TableCell>
      <TableCell align="left">Status</TableCell>
      <TableCell align="left">Created At</TableCell>
      <TableCell align="left">Updated At</TableCell>
    </TableHead>
    <TableBody>
      {bankTransfers.map((bankTransfer) => (
        <NetTransferTableRow key={bankTransfer.bankTransferId} bankTransfer={bankTransfer} />
      ))}
    </TableBody>
  </Table>
)

const NetTransferTableRow = ({ bankTransfer }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ maxWidth: 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
          </IconButton>
        </TableCell>
        <TableCell align="left">{bankTransfer.bankTransferId}</TableCell>
        <TableCell align="left">{bankTransfer.bankTransferAccountType}</TableCell>
        <TableCell align="left">{`${currencyWithTwoDecimals(bankTransfer.bankTransferAmount, '')} BRL`}</TableCell>
        <TableCell align="left">{bankTransfer.bankTransferStatus}</TableCell>
        <TableCell align="left">{convertToBrazilianDateTimeZone(bankTransfer.bankTransferCreatedAt)}</TableCell>
        <TableCell align="left">{convertToBrazilianDateTimeZone(bankTransfer.bankTransferUpdatedAt)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Bank Transfer Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Soft descriptor</TableCell>
                    <TableCell align="left">Business line</TableCell>
                    <TableCell align="left">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bankTransfer.merchants.map((merchant) => (
                    <TableRow key={merchant.id}>
                      <TableCell align="left">{merchant.softDescriptor}</TableCell>
                      <TableCell align="left">{merchant.businessLine}</TableCell>
                      <TableCell align="left">{`${currencyWithTwoDecimals(merchant.amount, '')} BRL`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default NetTransferRequestedDetails
