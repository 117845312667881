import { Divider } from '@mui/material'
import AvailableBalanceButNotReadyToSettle from './AvailableBalanceButNotReadyToSettle'
import SettlementBatchToApprove from './SettlementBatchToApprove'
import SettlementBatchToFundSend from './SettlementBatchToFundSend'
import { Breadcrumb } from '../../../../matx'
import SettlementBatchToWithdrawal from './SettlementBatchToWithdrawal'

const SettlementBatchPage = () => {
  return (
    <>
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'Settlements', path: '/settlement-batch' }, { name: 'Batch' }]} />
        </div>
        <AvailableBalanceButNotReadyToSettle />
        <SettlementBatchToApprove />
        <Divider style={{ marginBottom: 30 }} />
        <SettlementBatchToWithdrawal />
        <Divider style={{ marginBottom: 30 }} />
        <SettlementBatchToFundSend />
      </div>
    </>
  )
}

export default SettlementBatchPage
