import ProviderDetailsPage from './ProviderDetailsPage'
import Providers from './Providers'
import BankReconciliationPage from './reconciliation/BankReconciliationPage'

const ProviderRoutes = [
  {
    path: '/providers',
    element: <Providers />,
  },
  {
    path: '/providers/:providerId',
    element: <ProviderDetailsPage />,
  },
  {
    path: '/bank-reconciliation',
    element: <BankReconciliationPage />,
  },
]

export default ProviderRoutes
