import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Component } from 'react'
import { withNavigate } from '../../../../utils'
import StatusLabel from '../../../components/StatusLabel'
import { convertToBrazilianDateTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import { defineColorStatus } from '../../../utils/utils'
import TruncatedTextWithTooltip from '../../../components/TruncatedTextWithTooltip'

const PAYIN_STATUS_LIST = {
  ACTION_REQUIRED: ['AUTHORIZED'],
  PAID_LIST: ['CAPTURED', 'PAID'],
  REFUNDED_LIST: ['REFUNDED', 'PARTIALLY_REFUNDED', 'CHARGEBACK_REFUNDED', 'CHARGEBACK_PARTIALLY_REFUNDED'],
  DENIED_LIST: ['DENIED', 'ANALYSIS_FAILED', 'EXPIRED'],
  FAILED_LIST: ['FAILED', 'EXTERNAL_FAILURE'],
  CHARGEBACK_ACTION_REQUIRED: ['CHARGEBACK_OPENED', 'CHARGEBACK_DISPUTE'],
  CHARGEBACK_WON: ['CHARGEBACK_DISPUTE_WON', 'CHARGEBACK_CANCELED'],
}

class PayinTransactionTableContent extends Component {
  render() {
    let { items, navigate } = this.props
    let columns = [
      'Transaction Id',
      'Externalized Id',
      'Soft Descriptor',
      'Customer Name',
      'Payment Method',
      'Business Line',
      'Status',
      'Amount',
      'Date',
    ]

    return (
      <Table style={{ whiteSpace: 'pre' }}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} className="px-0">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length > 0 ? (
            items.map((item, index) => (
              <PayinTransactionTableRow key={index} item={item} index={index} navigate={navigate} />
            ))
          ) : (
            <TableRow>
              <TableCell>Sem resultados</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }
}

class PayinTransactionTableRow extends Component {
  handleClick = (event) => {
    this.props.navigate(`/payin/transaction/${this.props.item.id}`)
  }

  render() {
    let { item, index } = this.props
    let columnContent = [
      item.id,
      item.externalId,
      item.softDescriptor,
      <TruncatedTextWithTooltip text={item?.customerName} />,
      item.paymentMethod,
      <TruncatedTextWithTooltip text={item.businessLine} />,
      <StatusLabel color={this.defineStatusColorPayin(item.status)} label={item.status} />,
      currencyWithTwoDecimals(item.amount),
      convertToBrazilianDateTimeZone(item.createdAt),
    ]

    let cells = columnContent.map((content, index) => (
      <TableCell key={index} className="px-0 capitalize" align="left">
        {content}
      </TableCell>
    ))

    return (
      <TableRow key={index} onClick={this.handleClick}>
        {cells}
      </TableRow>
    )
  }

  defineStatusColorPayin(status) {
    return defineColorStatus({
      status,
      deniedList: PAYIN_STATUS_LIST.DENIED_LIST,
      failedList: PAYIN_STATUS_LIST.FAILED_LIST,
      actionRequiredList: PAYIN_STATUS_LIST.ACTION_REQUIRED,
      paidList: PAYIN_STATUS_LIST.PAID_LIST,
      refundedList: PAYIN_STATUS_LIST.REFUNDED_LIST,
      chargebackActionRequiredList: PAYIN_STATUS_LIST.CHARGEBACK_ACTION_REQUIRED,
      chargebackWonList: PAYIN_STATUS_LIST.CHARGEBACK_WON,
    })
  }
}

export default withNavigate(PayinTransactionTableContent)
