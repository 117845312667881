import { TableRow, TableCell } from '@mui/material'
import { Component } from 'react'
import { withNavigate } from '../../../utils'
import { convertToBrazilianDateTimeZone } from '../../services/FormatterService'

class CustomerTableRow extends Component {
  handleClick = (_) => {
    this.props.navigate(`/customers/${this.props.item.id}`)
  }

  render() {
    let { item, index } = this.props
    let columnContent = [
      item.documentId,
      item.name,
      item.result,
      convertToBrazilianDateTimeZone(item.firstPayinPaidTransactionDateTime),
      convertToBrazilianDateTimeZone(item.lastPayinPaidTransactionDateTime),
      item.numberOfPayinTransactions,
      item.numberOfPayoutTransactions,
    ]

    let cells = columnContent.map((content, index) => (
      <TableCell key={index} className="px-0 capitalize" align="left">
        {content}
      </TableCell>
    ))

    return (
      <TableRow key={index} onClick={this.handleClick}>
        {cells}
      </TableRow>
    )
  }
}

export default withNavigate(CustomerTableRow)
