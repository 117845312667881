import { convertToBrazilianDateTimeZone } from '../../../../services/FormatterService'

export default function CommentsDetails({ comments }) {
  return (
    <>
      <h5>Comments:</h5>
      {comments
        .sort((a, b) => a.id - b.id)
        .map((item) => (
          <p>
            Commented by {item.operator.userName} at {convertToBrazilianDateTimeZone(item.createdAt)}: {item.comment}
          </p>
        ))}
    </>
  )
}
