import { TableRow, TableCell } from '@mui/material'
import { Component } from 'react'
import { withNavigate } from '../../../utils'
import { convertToBrazilianDateTimeZone } from '../../services/FormatterService'

class SettlementTableRow extends Component {
  handleClick = (_) => {
    this.props.navigate(`/settlements/${this.props.item.id}`)
  }

  render() {
    let { item, index } = this.props
    let columnContent = [
      item.id,
      item.externalId,
      convertToBrazilianDateTimeZone(item.requestedDate),
      convertToBrazilianDateTimeZone(item.approvedDate),
      convertToBrazilianDateTimeZone(item.executionDate),
      item.settlementAmount,
      item.merchantFxTaxAmount,
      item.settlementAmountAfterDeductingFxTax,
      item.status,
    ]

    let cells = columnContent.map((content, index) => (
      <TableCell key={index} className="px-0 capitalize" align="left">
        {content}
      </TableCell>
    ))

    return (
      <TableRow key={index} onClick={this.handleClick}>
        {cells}
      </TableRow>
    )
  }
}

export default withNavigate(SettlementTableRow)
