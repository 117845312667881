import React, { useRef, useState, useEffect } from 'react'
import { Tooltip, Typography } from '@mui/material'

export default function TruncatedTextWithTooltip({ text }) {
  const textRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (textRef.current) {
      const element = textRef.current
      setIsTruncated(element.scrollWidth > element.clientWidth)
    }
  }, [text])

  const displayedText = isTruncated ? text.slice(0, textRef.current.clientWidth) + '...' : text

  return (
    <Tooltip title={isTruncated ? text : ''} arrow>
      <Typography
        ref={textRef}
        variant="body2"
        noWrap
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: '200px',
          cursor: isTruncated ? 'pointer' : 'default',
        }}
      >
        {displayedText}
      </Typography>
    </Tooltip>
  )
}
