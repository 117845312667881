import React from 'react'
import BTable from 'react-bootstrap/Table'
import { SimpleCard } from '../../../../matx'
import PixProviderChargeDetails from './PixProviderChargeDetails'
import BoletoProviderChargeDetails from './BoletoProviderChargeDetails'
import { convertToBrazilianDateTimeZone } from '../../../services/FormatterService'
import IdentityProviderChargeDetails from './IdentityProviderChargeDetails'
import BankTransferProviderChargeDetails from './BankTransferProviderChargeDetails'

export default function ProviderChargeDetail({ providerChargeConfiguration, charges }) {
  const ChargeDetails = () => {
    switch (providerChargeConfiguration.provider.serviceType) {
      case 'PIX':
        return <PixProviderChargeDetails pixProviderCharges={charges} />
      case 'BOLETO':
        return <BoletoProviderChargeDetails boletoProviderCharges={charges} />
      case 'BANK_TRANSFER':
        return <BankTransferProviderChargeDetails bankTransferProviderCharges={charges} />
      case 'IDENTITY':
        return <IdentityProviderChargeDetails identityProviderCharges={charges} />
      default:
        return <></>
    }
  }

  return (
    <>
      <SimpleCard>
        <h5>Charge:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Id</td>
              <td>{providerChargeConfiguration.id}</td>
            </tr>
            <tr>
              <td>Charge type</td>
              <td>{providerChargeConfiguration.chargeType}</td>
            </tr>
            <tr>
              <td>Charge in days</td>
              <td>{providerChargeConfiguration.chargeInDays}</td>
            </tr>
            <tr>
              <td>Is charge business days</td>
              <td>{String(providerChargeConfiguration.isChargeBusinessDays)}</td>
            </tr>
            <tr>
              <td>Charge relative of</td>
              <td>{providerChargeConfiguration.chargeRelativeOf}</td>
            </tr>
            <tr>
              <td>Effective date</td>
              <td>{providerChargeConfiguration.effectiveDate}</td>
            </tr>
            <tr>
              <td>Minimum total charge amount</td>
              <td>{providerChargeConfiguration.minimumTotalChargeAmount}</td>
            </tr>
            <tr>
              <td>Created at BRT</td>
              <td>{convertToBrazilianDateTimeZone(providerChargeConfiguration.createdAt)}</td>
            </tr>
          </tbody>
        </BTable>
        <ChargeDetails />
      </SimpleCard>
    </>
  )
}
