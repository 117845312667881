import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Component } from 'react'
import { withNavigate } from '../../../utils'
import StatusLabel from '../../components/StatusLabel'
import { convertToBrazilianDateTimeZone, currencyWithTwoDecimals } from '../../services/FormatterService'
import { defineColorStatus } from '../../utils/utils'

const PAYOUT_STATUS_LIST = {
  PAID_LIST: ['PAID'],
  DENIED_LIST: ['DENIED'],
  ACTION_REQUIRED_LIST: ['AML_APPROVED'],
  FAILED_LIST: ['FAILED', 'EXTERNAL_FAILURE', 'EXPIRED'],
}

class PayoutTransactionTableContent extends Component {
  render() {
    let { items, navigate } = this.props
    let columns = ['Transaction Id', 'Externalized Id', 'Soft Descriptor', 'Customer Name', 'Status', 'Amount', 'Date']

    return (
      <Table style={{ whiteSpace: 'pre' }}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} className="px-0">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length > 0 ? (
            items.map((item, index) => (
              <TransactionTableRow key={index} item={item} index={index} navigate={navigate} />
            ))
          ) : (
            <TableRow>
              <TableCell>Sem resultados</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }
}

class TransactionTableRow extends Component {
  handleClick = (event) => {
    this.props.navigate(`/payout/transaction/${this.props.item.id}`)
  }

  render() {
    let { item, index } = this.props
    let columnContent = [
      item.id,
      item.externalizedId,
      item.merchantSoftDescriptor,
      item.customerName,
      <StatusLabel color={this.defineStatusColorPayout(item.status)} label={item.status} />,
      currencyWithTwoDecimals(item.amount),
      convertToBrazilianDateTimeZone(item.createdAt),
    ]

    let cells = columnContent.map((content, index) => (
      <TableCell key={index} className="px-0 capitalize" align="left">
        {content}
      </TableCell>
    ))

    return (
      <TableRow key={index} onClick={this.handleClick}>
        {cells}
      </TableRow>
    )
  }

  mountStatus(status) {
    return (
      <small className={`capitalize text-white ${this.checkStatus(status)} border-radius-4 px-10 py-10px`}>
        {status}
      </small>
    )
  }

  defineStatusColorPayout(status) {
    return defineColorStatus({
      status,
      paidList: PAYOUT_STATUS_LIST.PAID_LIST,
      deniedList: PAYOUT_STATUS_LIST.DENIED_LIST,
      actionRequiredList: PAYOUT_STATUS_LIST.ACTION_REQUIRED_LIST,
      failedList: PAYOUT_STATUS_LIST.FAILED_LIST,
    })
  }
}

export default withNavigate(PayoutTransactionTableContent)
