import { CredentialsModalsFragment } from '../credentials/CredentialsModalsFragment'
import { ViewCredentialsModalsFragment } from '../credentials/ViewCredentialsModalsFragment'
import { IntermediaryModalsFragment } from '../intermediary/IntermediaryModalsFragment'

function ManagementCredentials({ accountId, externalizedId, softDescriptor, isApproved }) {
  return (
    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <IntermediaryModalsFragment accountId={accountId} />
      </div>
      <div>
        <CredentialsModalsFragment
          accountId={accountId}
          externalizedId={externalizedId}
          softDescriptor={softDescriptor}
          isApproved={isApproved}
        />
      </div>
      <div>
        <ViewCredentialsModalsFragment accountId={accountId} />
      </div>
    </div>
  )
}

export default ManagementCredentials
