import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Component } from 'react'
import { withNavigate } from '../../../utils'
import { StatusLabel } from '../../components/StatusLabel'
import { convertToBrazilianDateTimeZone } from '../../services/FormatterService'
import { defineColorStatus } from '../../utils/utils'

const PAYEE_STATUS_LIST = {
  APPROVED_LIST: ['APPROVED'],
  DENIED_LIST: ['DENIED'],
  FAILED_LIST: ['FAILED', 'EXTERNAL_FAILURE'],
}

class PayeeMerchantTableContent extends Component {
  render() {
    let { items } = this.props
    let columns = ['Payee Merchant Name', 'Customer Id', 'Name', 'Bank Account ID', 'Customer Result', 'Date']

    return (
      <Table style={{ whiteSpace: 'pre' }}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} className="px-0">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length > 0 ? (
            items.map((item, index) => (
              <PayeeMerchantTableRow key={index} item={item} index={index} navigate={this.props.navigate} />
            ))
          ) : (
            <TableRow>
              <TableCell>Sem resultados</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }
}

class PayeeMerchantTableRow extends Component {
  handleClick = (event) => {
    this.props.navigate(`/payee-merchant/${this.props.item.id}`)
  }

  render() {
    let { item, index } = this.props
    let columnContent = [
      item.merchantSoftDescriptor,
      item.customerId,
      item.name,
      item.payeeBankAccount.id,
      <StatusLabel color={this.defineStatusColorPayee(item.customerResult)} label={item.customerResult} />,
      convertToBrazilianDateTimeZone(item.createdAt),
    ]

    let cells = columnContent.map((content, index) => (
      <TableCell key={index} className="px-0 capitalize" align="left">
        {content}
      </TableCell>
    ))

    return (
      <TableRow key={index} onClick={this.handleClick}>
        {cells}
      </TableRow>
    )
  }

  defineStatusColorPayee(status) {
    return defineColorStatus({
      status,
      approvedList: PAYEE_STATUS_LIST.APPROVED_LIST,
      deniedList: PAYEE_STATUS_LIST.DENIED_LIST,
      failedList: PAYEE_STATUS_LIST.FAILED_LIST,
    })
  }
}

export default withNavigate(PayeeMerchantTableContent)
