import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { convertToBrazilianDateTimeZone } from '../../../services/FormatterService'
import { useNavigate } from 'react-router-dom'

export default function AccountTableContent({ items }) {
  const navigate = useNavigate()

  function detailsRedirect(_, id) {
    navigate(`/account/${id}`)
  }

  function updateRedirect(_, id) {
    navigate(`/update/account/${id}`)
  }

  return (
    <Table style={{ whiteSpace: 'pre' }}>
      <TableHead>
        <TableRow>
          <TableCell className="px-0">Account id</TableCell>
          <TableCell className="px-0">Intermediary id</TableCell>
          <TableCell className="px-0">Trade name</TableCell>
          <TableCell className="px-0">Soft descriptor</TableCell>
          <TableCell className="px-0">Business line</TableCell>
          <TableCell className="px-0">Status</TableCell>
          <TableCell className="px-0">Created at</TableCell>
          <TableCell className="px-0">Edit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.length > 0 ? (
          items.map((account, index) => (
            <TableRow key={index}>
              <TableCell
                onClick={(event) => detailsRedirect(event, account.accountId)}
                className="px-0 capitalize"
                align="left"
              >
                {account.accountId}
              </TableCell>
              <TableCell
                onClick={(event) => detailsRedirect(event, account.accountId)}
                className="px-0 capitalize"
                align="left"
              >
                {account.intermediarySoftDescriptor}
              </TableCell>
              <TableCell
                onClick={(event) => detailsRedirect(event, account.accountId)}
                className="px-0 capitalize"
                align="left"
              >
                {account.tradeName}
              </TableCell>
              <TableCell className="px-0 capitalize" align="left">
                {account.softDescriptor}
              </TableCell>
              <TableCell className="px-0 capitalize" align="left">
                {account.businessLine.substring(0, 25)}
              </TableCell>
              <TableCell onClick={(event) => detailsRedirect(event, account.accountId)} className="px-0 capitalize">
                <div>
                  {(() => {
                    const status = account.status
                    let bgColor = 'bg-account-active'
                    if (status === 'INACTIVE') {
                      bgColor = 'bg-account-inactive'
                    } else if (status === 'ON_ANALYSIS') {
                      bgColor = 'bg-account-onanalysis'
                    }
                    return (
                      <small className={`capitalize text-white ${bgColor} border-radius-4 px-10 py-10px`}>
                        {status}
                      </small>
                    )
                  })()}
                </div>
              </TableCell>
              <TableCell onClick={(event) => detailsRedirect(event, account.accountId)}>
                {convertToBrazilianDateTimeZone(account.createdAt)}
              </TableCell>
              <TableCell onClick={(event) => updateRedirect(event, account.accountId)}>
                <FontAwesomeIcon icon={faEdit} />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell>Sem resultados</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
