export const navigations = [
  {
    name: 'Providers',
    path: '/providers',
    icon: 'businessCenter',
    children: [
      {
        name: 'Charge configuration',
        path: '/providers',
        icon: 'businessCenter',
      },
      {
        name: 'Bank reconciliation',
        path: '/bank-reconciliation',
        icon: 'businessCenter',
      },
    ],
  },
  {
    name: 'Accounts',
    path: '/account',
    icon: 'format_list_bulleted',
    children: [
      {
        name: 'Account List',
        path: '/account',
        icon: 'format_list_bulleted',
      },
      {
        name: 'Accounts to approve',
        path: '/account_to_approve',
        icon: 'check_circle',
      },
      {
        name: 'Net credited',
        path: '/net-credited',
        icon: 'paid',
      },
    ],
  },
  {
    name: 'CERC',
    path: '/cerc',
    icon: 'today',
    children: [
      {
        name: 'CERC Receivables',
        path: '/cerc/receivable',
        icon: 'account_balance_wallet',
      },
      {
        name: 'CERC Calendar',
        path: '/cerc/receivable/calendar',
        icon: 'today',
      },
    ],
  },
  {
    name: 'Payin',
    icon: 'payment',
    children: [
      {
        name: 'Transaction',
        path: '/payin/transaction',
        icon: 'format_list_numbered',
      },
      {
        name: 'Refunds',
        path: '/payin/refund',
        icon: 'money_off',
      },
      {
        name: 'Chargebacks',
        path: '/payin/chargeback',
        icon: 'arrow_back',
      },
    ],
  },
  {
    name: 'Settlements',
    icon: 'account_balance',
    children: [
      {
        name: 'Settlements',
        path: '/settlements',
        icon: 'view_list',
      },
      {
        name: 'File',
        path: '/settlement-file',
        icon: 'upload_file_icon',
      },
      {
        name: 'Batch for B2B',
        path: '/settlement-batch',
        icon: 'post_add',
      },
    ],
  },
  {
    name: 'Payout',
    icon: 'monetization_on',
    children: [
      {
        name: 'Payee Merchant',
        path: '/payee-merchant',
        icon: 'view_list',
      },
      {
        name: 'Payout Transactions',
        path: '/payout/transaction',
        icon: 'format_list_numbered',
      },
    ],
  },
  {
    name: 'Customers',
    path: '/customers',
    icon: 'person',
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: 'assessment',
  },
]
