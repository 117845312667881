import { Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@mui/material'
import { SimpleCard } from '../../../../matx'
import { convertToBrazilianDateTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import { useEffect, useState } from 'react'
import { getAvailableBalanceNotReadyToSettle } from '../../../services/APIClient'
import Loading from '../../../components/Loading'

const columns = [
  'Merchant soft descriptor',
  'Merchant ID',
  'Balance date time',
  'Local currency amount',
  'Foreing currency amount',
]

export default function SettlementBatchToApprove() {
  const [isLoading, setIsLoading] = useState(true)
  const [availableBalanceNotReadyToSettle, setAvailableBalanceNotReadyToSettle] = useState([])

  useEffect(() => {
    getAvailableBalanceNotReadyToSettle()
      .then((response) => {
        setAvailableBalanceNotReadyToSettle(response)
      })
      .finally(() => setIsLoading(false))
  }, [])

  const totalAmountInLocalCurrency = availableBalanceNotReadyToSettle.reduce(
    (init, element) => init + element.amount,
    0,
  )
  const totalAmountInForeignCurrency = availableBalanceNotReadyToSettle.reduce(
    (init, element) => init + element.foreignCurrencyAmount,
    0,
  )

  return (
    <div className="m-sm-30">
      <SimpleCard title="Available B2B balance but not ready to approve settlement">
        {isLoading ? (
          <Loading />
        ) : availableBalanceNotReadyToSettle.length > 0 ? (
          <div>
            <Table style={{ whiteSpace: 'pre' }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={`header-${column}`} className="px-0">
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {availableBalanceNotReadyToSettle.map((item) => (
                  <TableRow>
                    <TableCell className="px-0 capitalize" align="left">
                      {item.merchantSoftDescriptor}
                    </TableCell>
                    <TableCell className="px-0 capitalize" align="left">
                      {item.merchantId}
                    </TableCell>
                    <TableCell className="px-0 capitalize" align="left">
                      {convertToBrazilianDateTimeZone(item.balanceDateTime)}
                    </TableCell>
                    <TableCell className="px-0 capitalize" align="left">
                      {`${currencyWithTwoDecimals(item.amount, '')} BRL`}
                    </TableCell>
                    <TableCell className="px-0 capitalize" align="left">
                      {`${currencyWithTwoDecimals(item.foreignCurrencyAmount, '')} ${item.foreignCurrency}`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <div style={{ marginTop: 30 }}>
                  {`Total amount in local currency: ${currencyWithTwoDecimals(totalAmountInLocalCurrency)}`}
                </div>
                <div style={{ marginTop: 30 }}>
                  {`Total amount in foreign currency: ${currencyWithTwoDecimals(totalAmountInForeignCurrency, '$')}`}
                </div>
              </TableFooter>
            </Table>
          </div>
        ) : (
          <TableRow>
            <TableCell>There is no available balance that are not ready to settle</TableCell>
          </TableRow>
        )}
      </SimpleCard>
    </div>
  )
}
